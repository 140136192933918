
import { Options, Vue } from "vue-class-component";
import newsEntries from "@/assets/news.json";

interface NewsEntry {
	id: string;
	title: string;
	date: string;
	author: string;
	html: string;
}

@Options({
	props: {
		count: Number,
		from: Number,
	}
})
export default class News extends Vue {
	private count = Infinity;
	private from = 0;
	private entries: NewsEntry[] = (newsEntries as NewsEntry[]).slice(this.from, this.count);
	private fullyLoaded = false;

	beautify (): void {
		this.entries.forEach(entry => {
			// FIXME: weird Vue bug
			entry.html = entry.html.replace(/<br\/>/g,"<br></br>");

			// compare the entry title with its first line:
			const compare = `<b>${entry.title}</b><br></br>`;

			if (entry.html.startsWith(compare)) {
				// duplicate title: remove the extra one
				entry.html = entry.html.slice(compare.length);
			}
		});
	}

	mounted (): void {
		if (!process.env.VUE_APP_NEWS_JSON || !process.env.VUE_APP_NEWS_JSON.startsWith("https")) {
			this.beautify();
			// no extra news to load so end here
			return;
		}

		// restore from cache while we're loading a fresh copy
		if (Reflect.has(self, "localStorage")) {
			const newsCache = localStorage.getItem("newsCache");

			if (newsCache !== null) {
				this.entries = (JSON.parse(newsCache) as NewsEntry[]).slice(this.from, this.count);
			}
		}

		// initial rendering
		this.beautify();

		if (this.count === 1 && this.entries.length >= 1) {
			// don't loaad extra news unprompted
			return;
		}

		const req = new Request(process.env.VUE_APP_NEWS_JSON, {
			method: "GET",
			cache: "default", // serve if fresh, else fetch
		});

		fetch(req)
		.then(data => data.json())
		.then(data => {
			this.entries = (data as NewsEntry[]).slice(this.from, this.count);
			this.fullyLoaded = true;
			this.beautify();

			if (document.location.hash) {
				let el = null;

				if ((el = document.getElementById(document.location.hash.slice(1))) !== null) {
					el.scrollIntoView(true);
				}
			}

			if (Reflect.has(self, "localStorage")) {
				localStorage.setItem("newsCache", JSON.stringify(data));
			}
		})
		.catch(() => {
			// no news (will use cache, if any)
		})
	}
}
