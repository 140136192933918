
import { Options, Vue } from "vue-class-component";
import Navigation from "@/components/Navigation.vue";
import Logo from "@/components/Logo.vue";
import Copyright from "@/components/Footer.vue";

@Options({
	components: {
		Navigation,
		Logo,
		Copyright,
	},
})
export default class AppV extends Vue {
	loaded = false;

	globalStatus = process.env.VUE_APP_STATUS?.trim() ?? "";

	mounted (): void {
		self.addEventListener("initial-load", () => {
			this.loaded = true;
		});
	}
}
