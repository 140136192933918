
import { Options, Vue } from "vue-class-component";
import ServerStatus from "@/components/ServerStatus.vue";

@Options({
	components: {
		ServerStatus,
	},
	computed: {
		// XXX: find a better way to do this

		isSupport() {
			return this.$route.path.startsWith("/recover");
		},
		isHome() {
			return this.$route.path.startsWith("/news");
		}
	}
})
export default class NavigationV extends Vue {}
